.StatsCard {
    display: grid;
    grid-template-rows: 2fr 1fr;
    gap: 15px
}

.WindPodium {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    min-width: 300px;
}

.RideStats {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.PodiumBar {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    gap: 0;
}

.PodiumBar h2 {
    margin: 0;
    padding: 0;
    color: black;
    font-size: 25px;
    text-align: center;
    line-height: 1;
    opacity: 0.8;
}

.PodiumBar p {
    margin: 0;
    padding: 0;
    text-align: center;
    opacity: 0.55;
    color: black;
    font-size: 12px;

}

.WindTypeBar {
    flex-grow: 1;
    border-radius: 8px;
    overflow: hidden;
}

.RideStatColumn {
    color: black;
    text-align: center;
    align-items: center;
    padding-top: 30px;
}

.RideStatColumn p {
    font-size: 14px;
    opacity: 0.7;
    padding: 0;
    margin: 0;
}

.RideStatColumn h2 {
    margin: 0;
    padding: 0;
    font-size: 19px;
    opacity: 0.8;
}