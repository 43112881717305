.Plugin p {
    padding-bottom: 20px;
}

.PluginMockup {
    width: 100%;
    margin-top: 5vh;
    margin-bottom: -60%;
}

.HeroSection {
    width: 80vw;
    background-color: #f5f5f3;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 20px;
    display: grid;
    grid-template-columns: calc(80vw - 33vw) 33vw;
    overflow: hidden;
}

.HeroSection h1 {
    color: #3C5B6F;
    font-size: 53px;
    font-style: bold;
    padding-top: 100px;
}

.HeroSection p {
    color: #3C5B6F;
    font-size: 18px;
    line-height: 1.6;
    padding-right: 100px;
    font-style: normal;
    font-weight: 550;
}

.EnablePluginButton {
    background-color: #3C5B6F;
    border-radius: 50px;
    padding: 9px;
    padding-left: 15px;
    padding-right: 15px;
    display: table;
    position: relative;
    margin: 0;
    z-index: 10;
    transform-origin: center center;
    transition: all 0.1s;
    margin-bottom: 50px;
}

.EnablePluginButton p {
    margin: 0;
    padding: 0;
    font-size: 18px;
    color: white;
    opacity: 1;
}

.EnablePluginButton:hover {
    cursor: pointer;
    scale: 1.03;
}

.Enabled {
    color: #3C5B6F;
    font-size: 25px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-grow: 0;
    height: 25px;
    margin-bottom: 15px;
    opacity: 0.8;
}

.Enabled h3 {
    margin: 0;
    padding: 0;
    line-height: 1;
}

.Enabled img {
    height: 20px;
    margin-top: 3px;
}

.DisablePluginButton {
    padding: 6px;
    display: table;
    position: relative;
    margin: 0;
    z-index: 100;
    opacity: 0.7;
    font-size: 13px;
    text-decoration: underline;
    color: #3C5B6F;
}

.DisablePluginButton:hover {
    cursor: pointer;
}

.PluginBottom {
    width: 80vw;
    background-color: #3C5B6F;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 20px;
}

.PluginBottom h1 {
    padding-top: 30px;
    color: #f5f5f3;
    font-weight: 700;

}

.PluginBottom p {
    font-weight: 600;
    color: #f5f5f3;
}

.PluginImage {
    height: 250px;
    padding-top: 50px;
    padding-bottom: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 800px) {
    .HeroSection { 
        padding-top: 0;
        grid-template-columns: 1fr;
        width: 90vw;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .HeroSection h1 {
        padding-top: 0;
        padding-right: 0;
    }
    .EnablePluginButton {
        margin-bottom: 0;
    }
    .PluginBottom {
        width: 90vw;
        padding-left: 5vw;
        padding-right: 5vw;
    }
    .HeroSection p {
        padding-right: 0;
    }
}