.Subscribe h1 {
    padding-top: 10px;
}

.Subscribe p {
    padding-bottom: 20px;
}

.SubscriptionGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.SubscriptionPlanCard {
    background-color: white;
    border-radius: 40px;
    transition: all 0.1s;
}

.SubscriptionCardTitle {
    font-size: 25px;
    padding-left: 20px;
    background-color: #3C5B6F;
    border-radius: 40px 40px 0 0;
    margin: 0;
    padding-top: 15px;
    padding-bottom: 10px;
    color: white !important;
}


.SubscriptionFeatureList {
    margin: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.SubscriptionFeatureList div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
}

.SubscriptionFeatureList img {
    height: 23px;
}

.SubscriptionFeatureList p {
    margin: 0;
    margin-top: 3px;
}

.SubscriptionPlanCard:hover {
    scale: 1.005;
}

.WindMatePlus:hover {
    scale: 1.04;
    translate: 0 -3px;
}

.SubscribedIndicator {
    border-radius: 15px;
    background-color: #6795c9;
    color: white;
    padding: 12px;
    margin: 20px;
    margin-bottom: 0;
    display: flex;
    align-items: baseline;
    justify-content: center;   
    gap: 10px;
}

.SubscribedIndicator h1 {
    margin: 0;
    font-size: 22px;
    transition: all 0.2s;
    color: white;
}

.SubscribedIndicator img {
    margin: 0;
    height: 15px;
    margin-left: 10px;    
}

.Unsubscribe {
    text-decoration: underline;
    cursor: pointer;
}

.WindMatePlusButton {
    border-radius: 15px;
    background-color: #6795c9;
    color: white !important;
    padding: 12px;
    margin: 20px;
    margin-bottom: 0;
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.WindMatePlusButton p {
    margin: 0;
    opacity: 0.8;
}

.WindMatePlusButton h1 {
    margin: 0;
    font-size: 22px;
    margin-left: 20px;
    transition: all 0.2s;
    color: white;
}

.WindMatePlusButton img {
    margin: 0;
    height: 15px;
    margin-left: 10px;
    transform: scaleX(0);
    translate: -10px 0;
    transition: all 0.1s;
}

.WindMatePlusButton:hover img {
    transform: scaleX(1);
    translate: 0 0;
}

.WindMatePlusButton:hover h1 {
    margin-left: 0;
}

.WindMatePlusButton:hover {
    cursor: pointer;
}

.SubscriptionDetail {
    opacity: 0.6;
    font-size: 12px;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 20px;
    text-align: center;
}


.CurrentPlan {
    font-weight: 600;
    text-align: center;
    color: black;
    opacity: 0.5;
    filter: saturate(0.4);
    bottom: 0;
    left: 50%;
    margin-top: auto;
}

.Explanation1 {
    background-color: whitesmoke;
    border-radius: 15px;
}

.Explanation1 p{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
    color: black;
}

@media only screen and (max-width: 800px) {
    .FAQExplainerImage { display: none; }
    .SubscriptionGrid { grid-template-columns: 1fr; }
    .SubscriptionCardTitle {
        border-radius: 20px 20px 0 0;
    }
    .SubscriptionPlanCard {
        border-radius: 20px;
    }
}