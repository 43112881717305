
.TitleContent {
    /* height: 650px; */
    background-image: url('./../../Assets/LandingBackground.svg');
    background-size: cover; 
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    width: 100vw;
}

.LogoImage img {
    height: 32vw;
    margin-left: 14vw;
    margin-top: 120px;
    margin-right: -200px;
    padding-bottom: 20px;
}

.TitleText h1{
    color: #f5f5f3;
    font-size: 40px;
    margin-left: 60px;
    margin-right: 10vw;
    margin-top: 150px;
    text-align: right;
    max-width: 80%; /* Adjust the width as needed */
    word-wrap: break-word
}



.StartUsing {
    width: fit-content;
    background-color: #f5f5f3;
    border-radius: 60px;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    float: right;
    margin-right: 10vw;
    color: #3C5B6F;
    font-weight: 650;
    transition: all 0.1s;
    font-style: bold;
}

.StartUsing:hover {
    cursor: pointer;
    scale: 1.05;   
}

.LandingContent2 {
    background-color: #F6F5F2;
    display: grid;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 70px;
    padding-bottom: 30px;
    align-items: center;
    gap: 50px;
}

.LeftText {
    grid-template-columns: 1fr 1.4fr;
}

.RightText {
    grid-template-columns: 1.4fr 1fr;
}

.LandingContent2 img {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 7px rgb(210, 210, 210);
}

.LandingContent2 h2 {
    color: #3C5B6F;
    font-size: 45px;
    font-weight: 700;
    line-height: 1.2;
    margin-top: -10px;
}

.LandingContent2 p {
    color: #3C5B6F;
    font-size: 17px;
    font-weight: 500;
    margin-top: -10px;
}

.ImageExplanationLeft h2 {
    color: #3C5B6F;
    margin-top: 40px;
    font-size: 45px;
    padding-right: 50px;
    font-weight: 700;
}

.GoToPageButton {
    background-color: #3C5B6F;
    border-radius: 10px;
    width: fit-content;
    padding: 10px;
    color: white;
    transition: all 0.07s;
    font-weight: 700;
    font-size: 14px;
}

.GoToPageButton:hover {
    cursor: pointer;
    opacity: 0.85;
}

.SmallPluginMockup {
    scale: .95;
    margin-left: auto;
    margin-right: auto;
}

.MobileOnly { display: none; }

@media only screen and (max-width: 950px) {
    .LandingContent1 {
        display: grid;
        grid-template-rows: auto auto;
        height: auto;
        padding-top: 10px;
    }

    .LeftContent1 {
        grid-row: 2;
        grid-column: 1;
    }

    .LeftContent1 h1 {
        font-size: 30px;
    }

    .Preview {
        grid-row: 1;
        grid-column: 1;
        width: 90vw;
        padding-left: 5vw;
        margin-top: 0;
    }
    .StartUsing {
        margin-right: 5vw;
    }
}

@media only screen and (max-width: 1100px) {
    .LandingContent2 {
        padding-left: 5vw;
        padding-right: 5vw;
    }
    .TitleText h1 {
        max-width: 100%;
        margin-right: 5vw;
        margin-top: 50px;
        margin-left: 0;
    }
}

@media only screen and (max-width: 800px) {
    .LandingContent2 {
        display: flex;
        flex-direction: column;
        padding-left: 5vw;
        padding-right: 5vw;
        gap: 0;
        align-items: flex-start;
    }

    .MobileOnly { display: block; }

    .DesktopOnly { display: none; }

    .LandingContent2 img {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }

    .LandingContent2 p {
        margin-top: 20px;
    }

    .ImageExplanationRight {
        padding-left: 0;
    }

    .LeftContent1 {
        padding-top: 0;
    }

    .TitleContent {
        grid-template-columns: 1fr;
        background-image: url('./../../Assets/mobile-landing.png');
        width: 100vw;
        height: 117.44vw;
    }

    .LogoImage img {
        display: none;
    }

    .TitleText h1 {
        display: none;
    }

    .StartUsing {
        background-color: #3C5B6F;
        color: white;
        margin-right: auto;
        margin-left: auto;
        float: unset;
        margin-top: 50px;
    }
    
}