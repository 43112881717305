.EffortList {
    border-radius: 10px;
    margin-top: 5vw;
    margin-bottom: 30vw;
}

.EffortRow {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 2.4fr;
    gap: 10px;
    border-top: 1px solid rgb(220, 220, 220);
    align-items: center;
    float: right;
    padding-top: 7px;
}

.ViewOnStrava {
    opacity: 0.7;
    font-size: 11px;
    padding: 0;
    margin: 0;
    color: black;
}

.ViewOnStrava:hover {
    cursor: pointer;
}

.SegmentInfo h3 {
    font-size: 15px;
    margin: 0;
}

.Detail {
    float: right;
}

.Detail img {
    height: 17px;
    margin-left: 5px;
    margin-bottom: -1px;
}

.WindDetail {
    font-size: 19px;
}

.Headwind { color: var(--windRed); }
.Crosswind { color: var(--windYellow); }
.Tailwind { color: var(--windGreen); }
.DetailCaption { display: none; }

.EffortConditions h3 {
    float: right;
}

@media only screen and (max-width: 800px) {
    .EffortRow {
        display: grid;
        grid-template-rows: auto auto auto;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding-top: 20px;
        gap: 0;
    }
    .SegmentInfo {
        grid-column: 1 / span 4;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
    }

    .Time {
        grid-row: 3;
        grid-column: 1;
    }
    .Speed {
        grid-row: 3;
        grid-column: 2;
    }
    .HR {
        grid-row: 3;
        grid-column: 3;
    }
    .PWR {
        grid-row: 3;
        grid-column: 4;
    }
    .EffortConditions {
        grid-row: 2;
        grid-column: 1 / span 4;
        text-align: left;
        float: left;
        margin: 0;
        padding: 0;
    }
    .EffortConditions h3 {
        float: left;
    }
    .DetailCaption {
        display: block;
        font-size: 13px;
        opacity: 0.7;
        margin: 0;
    }
}