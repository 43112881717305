.SegmentsGrid {
    display: grid;
    gap: 20px;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.SegmentsContainer {
    background-color: var(--navyblue);
    width: 80vw;
    padding-left: 10vw;
    padding-right: 10vw;
    margin: 0;
    padding-top: 0;
    color: white;
    padding-bottom: 200px;
}

.SegmentsContainer h1 {
    margin: 0;
    padding-top: 50px;
    padding-bottom: 10px;
}

.SegmentCard {
    border-radius: 10px;
    transition: all 0.05s;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: 3px solid white;
}

.SegmentCard:hover {
    scale: 1.01;
    translate: 0 -2px;
    cursor: pointer;
}

.SearchBar {
    background-color: white;
    padding: 15px;
    border-radius: 12px;
    flex-grow: 1;
}

.SearchBar input {
    border: none;
    font-size: 15px;
}

.SearchBar img {
    height: 17px;
    opacity: 0.4;
    padding: 0;
    margin: 0;
    margin-right: 10px;
    translate: 0 3px;
}

.SearchBar input:focus {
    outline: none;
}

.BottomRight {
    grid-column: 1;
    grid-row: 1;

    align-self: flex-end;
    text-align: end;
    width: fit-content;

    display: flex;
    gap: 15px;

    padding-right: 10px;
    padding-left: 12px;
    padding-top: 2px;
    z-index: 5;
    margin-left: auto;

    background-color: var(--navyblue);
    color: white;
    border-radius: 15px 0 0 0;
}

.BottomRight p {
    font-weight: 600;
    font-size: 14px;
    margin: 0;
}

.SegmentCard h3 {
    margin: 0;
    padding: 0;
    font-size: 15px;
}

.SegmentLocation {
    font-size: 12px;
    opacity: 0.6;
    margin: 0;
}

.TopBar {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;
}

.OpenInStrava {
    background-color: #fc4c02;
    color: white;
    font-weight: 600;
    padding: 14px;
    border-radius: 10px;
    filter: saturate(0.85);
}

.OpenInStrava:hover {
    cursor: pointer;
}

.SegmentInfoHeader {
    background-color: #b1c7dc;
    padding: 5px 10px 5px 10px;
    border-radius: 5px 5px 0 0;
    color: black;
}

.SegmentCardBottom {
    display: grid;
    flex-grow: 1;
    min-height: 200px;
}

.SmallSegmentMap {
    width: 100%;
    height: 100%;
    grid-row: 1;
    grid-column: 1;
    z-index: 1;
}

.MapPlaceholder {
    min-height: 200px;
    flex-grow: 1;
    background-color: #E4E9E4;
}


@media only screen and (max-width: 600px) {
    .SubtitleContainer { flex-direction: column;}
    .SegmentsContainer { width: 90vw; padding-left: 5vw; padding-right: 5vw; }
    
}