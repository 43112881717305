.DurationPicker {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    width: fit-content;
    gap: 3px;
    margin: 0;
    margin-bottom: 10px;
}

.DurationPicker div {
    background-color: white;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    height: fit-content;
    font-weight: 500;
    transition: all 0.1s;
}

.NotSelectedDuration {
    opacity: 0.7;
    scale: 0.98;
}

.SelectedDuration {
    opacity: 1;
    scale: 1.02;
}

.DurationPicker div:hover {
    cursor: pointer;
    scale: 1.02;
    opacity: 0.8;
}