
.UpperRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
}

.EffortList h2 {
    text-align: center;
    font-size: 32px;
    color: var(--navyblue);
}

.LiveCard {
    border-radius: 15px;
    height: 400px;
    display: grid;
    overflow: hidden;
}

.ForecastCard {
    background-color: #F2F3F0;
    border-radius: 15px;
    padding: 10px;
    height: 380px;
    padding-bottom: 10px;
    filter: saturate(0.85)
}

.Arrow {
    opacity: 0.3;
}

.Legend {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 2.4fr;
    float: right;
    width: 100%;
    gap: 20px;
    opacity: 0.9;
}

.LiveMap {
    grid-column: 1;
    grid-row: 1;
    width: 100%; 
    height: 100%;
    opacity: 0.999;
}

.CardTitle {
    grid-column: 1;
    grid-row: 1;
    z-index: 10;
    margin-left: 15px;
    width: fit-content;
    height: fit-content;
}

.SegmentContent {
    width: 80vw;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-bottom: 5vw;
    padding-top: 2vw;
    background-color: #3C5B6F;
}

.SegmentContent h2 {
    color: #F6F5F2;
    margin-bottom: 5px;
    margin-left: 0;
    flex-grow: 1;
}

.SegmentSummary {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items:baseline;
}

.SegmentSummary h1 {
    padding: 0;
    margin: 0;
}

.SegmentSummaryDistElev {
    display: flex;
    flex-direction: row;
    height: fit-content;
    font-weight: 600;
    color: white;
    align-items: baseline;
    line-height: 0;
    opacity: 0.88;
}

.SegmentSummaryDistElev img {
    height: 15px;
    margin-left: 30px;
    margin-right: 7px;
}

.SegmentDetails {
    color: white;
    margin: 0;
    opacity: 0.7;
    font-size: 14px;
    padding-bottom: 15px;
}

.SegmentDetails a {
    color: white;
}

.ConditionsChart {
    height: 100%;
    width: 100%;
}

.WindArrow {
    grid-row: 1;
    grid-column: 1;
    height: 40px;
    z-index: 10;
    opacity: 0.8;
    margin-bottom: 12px;
    margin-left: 22px;
    align-self: flex-end;
    justify-self: start;
    transition: all 0.1s;
}

.DottedBackground {
    background-image: radial-gradient(rgb(230, 230, 230) 10%, transparent 2%),
      radial-gradient(rgb(230, 230, 230) 10%, transparent 2%);
    background-color: #F2F3F0;
    background-position: 0 0, 0px 0px;
    background-size: 25px 25px;
}

.EffortContent {
    width: 80vw;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 5vw;
    margin-top: 2vw;
}

.SmallChartGrid {
    grid-template-columns: 1fr 1fr 1fr;
}

.ForecastCardHeader {
    flex-direction: row;
}

@media only screen and (max-width: 1100px) {
    .SegmentContent {
        width: 90vw;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .EffortContent {
        width: 90vw;
        margin-left: 5vw;
        margin-right: 5vw;
        padding-bottom: 150px;
    }
}

@media only screen and (max-width: 900px) {
    .UpperRow {
        grid-template-columns: 1fr;
    }

    .ForecastCardHeader {
        flex-direction: column;
    }
}

@media only screen and (max-width: 800px) {
    .SmallChartGrid {
        grid-template-columns: 1fr;
    }

    .SegmentSummary {
        flex-direction: column;
        gap: 0;
    }

    .SegmentSummaryDistElev img {
        margin-left: 0;
    }

    .SegmentSummaryDistElev p {
        margin-right: 30px;
    }
    .DesktopOnly {
        display: none;
    }
}