.loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    transition: opacity 0.3s ease;
  }

.loading-container-small {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  text-align: center;
  transition: opacity 0.3s ease;
}

.loading-spinner-small {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--color-1);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  animation: spin 1s linear infinite; /* Animation for spinner */
}
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: var(--color-1);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite; /* Animation for spinner */
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }