
.Footer {
    width: 100vw;
    background-color: var(--color-1);
    bottom: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

.FooterGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 6fr 1fr;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 20px;
    padding: 0;
    float: left;
    width: 80vw;
}

.FooterCol {
    display: flex;
    flex-direction: column;
}

.Footer a {
    color: white;
    text-decoration: none;
    margin-top: 12px;
    margin-bottom: 12px;
}

.Footer a:hover {
    text-decoration: underline;
    opacity: 0.8;
}

.APILogo {
    padding-top: 10px;
    width: 100%;
    margin-left: -6px;
}

.Footer p {
    color: white;
    opacity: 0.9;
    padding-top: 20px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 700px) {
    .FooterGrid {
        grid-template-columns: 1fr;
    }

    .APILogo {
        width: 50%;
        justify-self: center;
    }
}