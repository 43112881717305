.Navbar {
    width: 80vw;
    display: flex;
    flex-direction: row;
    color: white;
    background-color: #3C5B6F;
    z-index: 1000;
    padding-left: 10vw;
    padding-right: 10vw;
    align-items: center;
}

.NavbarAndDropdown {
    position: sticky;
    top: 0;
    z-index: 100;
}

.NavBarButton {
    font-size: 14px;
    transition: all 0.2s;
    text-decoration: none;
    margin: auto;
    color: white;
}

.NavbarLeft {
    display: flex;
    gap: 60px;
    flex-direction: row;
    font-optical-sizing: auto;
    font-style: normal;
}

.NavBarBranding {
    transition: all 0.2s;
    text-decoration: none;
    margin: 0;
    float: left;
    padding-top: 10px;
    padding-bottom: 10px;
}

.NavBarBranding a {
    color: #F6F5F2;
    text-decoration: none;
    padding: 0;
    margin: 0;
    font-size: 20px;
}

.Navbar a {
    font-weight: 550;
}

.NavBarMiddle {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
}

.NavBarRight {
    margin-left: auto;
}

.NavBarButton:hover {
    text-decoration: underline;
}

.SignInButton {
    filter: saturate(0.88);
    padding-top: 8px;
    padding-bottom: 2px;
}

.SignInButton:hover {
    cursor: pointer;
}


.SignOutDropdown {
    position: absolute;
    margin-top: 0px;
    color: white;
    padding: 10px;
    background-color: var(--navyblue);
    border-radius: 0 0 10px 10px;
    translate: 0;
    right: 0;
    margin-right: 10vw;
    font-size: 13px;
    display: none;
}

.DropdownVisible {
    translate: 0;
    opacity: 1;
    z-index: 3;
    display: block;
}


.FinishAuthButton:hover {
    cursor: pointer;
}


.SignOutDropdown:hover {
    cursor: pointer;
    text-decoration: underline;
}

.ExpandMenuButton { display: none; }

.NavbarDropdown {
    flex-direction: column;
    display: none;
    scale: 1 0;
    transition: all 0.1s;
    transform-origin: top;
    position: absolute;
}

.DropdownButton {
    color: white;
    background-color: #637C8C;
    padding: 12px;
    padding-left: 5vw;
    padding-right: 5vw;
    text-decoration: none;
    width: 90vw;
    z-index: 1000;
}

.DropdownButton:hover {
    background-color: #466376;
}

@media only screen and (max-width: 1100px) {
    .LargeNavBarOnly { display: none; }
    .ExpandMenuButton { 
        display: block;
        height: 12px;
        scale: 1 1.2;
    }
    .NavBarBranding { 
        display: flex;
        align-items: center;
        gap: 10px;
        padding-top: 18px;
        padding-bottom: 18px;
    }
    .ExpandMenuButton:hover {
        cursor: pointer;
    }
    .Navbar {
        padding-left: 5vw;
        padding-right: 5vw;
        width: 90vw;
    }
    .NavbarDropdown { display: flex; }
    .DropdownExpanded {
        scale: 1 1;
    }
}