.SignInPromptContainer {
    height: 80vh;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.SignInPromptContainer h1 {
    font-size: 200px;
    color: var(--color-1);
    line-height: 0.1;
    padding: 0;
}

.SignIn {
    box-shadow: 0 0 3px rgb(180, 180, 180);
    border-radius: 2px;
    margin: -5px;
    overflow: hidden;
}

.SignIn:hover {
    cursor: pointer;
}

.ButtonDiv:hover { cursor: pointer; scale: 1.07 }

.ButtonDiv img {
    height: 13px;
}

.ButtonDiv p {
    margin: 0;
}