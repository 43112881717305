/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Apply Poppins font to all elements within the body */
body, body * {
  font-family: 'Poppins', sans-serif;
}

/* Reset default margin */
body {
  margin: 0;
}

/* Other styles */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F3;
  filter: saturate(1.15);
}

.Content {
  width: 80vw;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-bottom: 5vw;
  padding-top: 50px;
  background-color: #F5F5F2;
  color: var(--navyblue)
}

.Content p {
  font-weight: 500;
}

.Alt {
  background-color: var(--navyblue) !important;
  color: #F5F5F2 !important;
}

.CenteredImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.App {
  text-align: center;
}

:root {
  /* Define color palette */
  --color-1: #3C5B6F;
  --color-2: #41C9E2;
  --color-3: #ACE2E1;
  --navyblue: #3C5B6F;
  --windRed: #DD5746;
  --windGreen: #AFD198;
  --windYellow: #FFC470;

  --temperatureOpaque: rgba(220, 134, 134, 1);
  --temperatureTransparent: rgba(220, 134, 134, 0.5);

  --pressureOpaque: rgba(129, 104, 157, 1);
  --pressureTransparent: rgba(129, 104, 157, 0.5);

  --windSpeedOpaque: rgba(155, 184, 205, 1);
  --windSpeedTransparent: rgba(155, 184, 205, 0.5);
}

.faq {
  font-size: 20px;
  margin-top: 50px;
  margin-bottom: 25px;
  transition: all 0.1s;
  user-select: none;
}

.faq:hover {
  cursor: pointer;
}

details {
  background-color: #F5F5F3;
}

.VerticalSpacer {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .Content {
    width: 90vw;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.PointerOnHover:hover {
  cursor: pointer;
}

@media only screen and (min-width: 800px) {
  .EffortRow:hover .EffortConditionsBar p {
    max-width: 180px;
  }
}

@media only screen and (max-width: 800px) {
  .EffortConditionsBar p {
    display: none;
  }
}

.EffortConditionsBar {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: hidden;
  transition: all 0.1s;
}

.EffortConditionsBar p {
  margin: 0;
  transition: all 0.1s;
  max-width: 0;
  overflow: hidden;
  height: 20px;
}

